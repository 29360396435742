
import { Options, Vue } from 'vue-class-component';
import {
  deleteAdminUserApi,
  loadAdminUsersApi,
  saveAdminUserApi,
  saveAdminUserPasswordApi,
  saveAdminUserSeatNameApi
} from "@/api/adminUser";
import {useCache} from "@/hooks/web/useCache";
import {ElMessage, ElMessageBox} from "element-plus";

@Options({
  data () {
    return {
      token: '',
      oldPassword: '',
      newPassword: '',
      seatName: '',
      isManager: '0',
      adminUsers: [],
      userInfo: {},
      userRules: {
        username: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        mobile: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        }],
        gender: [{
          required: true,
          message: '请选择性别'
        }],
        is_police: [{
          required: true,
          message: '请选择是否接警'
        }]
      }
    }
  },
  mounted() {
    const { wsCache } = useCache()

    this.wsCache = wsCache

    this.token = wsCache.get('token')
    this.seatName = wsCache.get('seat_name')
    this.isManager = wsCache.get('is_manager')

    if (this.isManager === '1') {
      this.loadAdminUsers()
    }
  },
  methods: {
    loadAdminUsers () {
      loadAdminUsersApi({
        token: this.token
      }).then((res: any) => {
        this.adminUsers = res.list.data
        console.log(res.list.data)
      })
    },
    modifyUser (user: any) {
      this.userInfo = user
    },
    deleteUser (user: any) {
      const self = this

      ElMessageBox.confirm(
          '确定要删除该用户？',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
      ).then(() => {
        deleteAdminUserApi({
          token: this.token,
          id: user.id
        }).then((res: any) => {
          console.log(res)
          ElMessage.success('删除成功')

          self.loadAdminUsers()
        })
      }).catch(() => {
        console.log('Canceled')
      })
    },
    saveUserInfo: function () {
      const self = this

      this.$refs.submitForm.validate((valid: boolean) => {
        if (valid) {
          self.userInfo.token = self.token

          saveAdminUserApi(self.userInfo).then((res: any) => {
            self.userInfo = {
              gender: 0,
              is_police: 0
            }
            self.loadAdminUsers()
          })
        }
      })
    },
    doSubmit() {
      saveAdminUserPasswordApi({
        token: this.token,
        old_password: this.oldPassword,
        new_password: this.newPassword
      }).then((res: any) => {
        ElMessage.success('设置成功')
      })
    },
    doSubmit2() {
      saveAdminUserSeatNameApi({
        token: this.token,
        seat_name: this.seatName
      }).then((res: any) => {
        this.$emit('syncSeatName', this.seatName)

        ElMessage.success('保存成功')
      })
    },
    closeSetting() {
      this.$emit('closeSetting')
    }
  }
})
export default class SystemSetting extends Vue {}
