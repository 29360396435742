// Constants used for the transformation
const pi = 3.14159265358979324;
const a = 6378245.0; // semi-major axis of the Earth
const ee = 0.00669342162296594323; // eccentricity squared

// Helper functions for the transformation
function transformLat(lng: number, lat: number) {
    let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
    ret += (20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(lat * pi) + 40.0 * Math.sin(lat / 3.0 * pi)) * 2.0 / 3.0;
    ret += (160.0 * Math.sin(lat / 12.0 * pi) + 320 * Math.sin(lat * pi / 30.0)) * 2.0 / 3.0;
    return ret;
}

function transformLon(lng: number, lat: number) {
    let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
    ret += (20.0 * Math.sin(6.0 * lng * pi) + 20.0 * Math.sin(2.0 * lng * pi)) * 2.0 / 3.0;
    ret += (20.0 * Math.sin(lng * pi) + 40.0 * Math.sin(lng / 3.0 * pi)) * 2.0 / 3.0;
    ret += (150.0 * Math.sin(lng / 12.0 * pi) + 300.0 * Math.sin(lng / 30.0 * pi)) * 2.0 / 3.0;
    return ret;
}

// The main function to convert GCJ-02 to WGS-84
function gcj02ToWgs84(lng: any, lat: any) {
    lng = lng - 0
    lat = lat - 0

    if (outOfChina(lng, lat)) {
        return [lng, lat];
    }
    let dLat = transformLat(lng - 105.0, lat - 35.0);
    let dLon = transformLon(lng - 105.0, lat - 35.0);
    const radLat = lat / 180.0 * pi;
    let magic = Math.sin(radLat);
    magic = 1 - ee * magic * magic;
    const sqrtMagic = Math.sqrt(magic);
    dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * pi);
    dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * pi);
    const mgLat = lat + dLat;
    const mgLon = lng + dLon;
    return [lng * 2 - mgLon, lat * 2 - mgLat];
}

// Function to determine if the point is outside of China
function outOfChina(lng: number, lat: number) {
    return (lng < 72.004 || lng > 137.8347 || lat < 0.8293 || lat > 55.8271);
}

// Example usage
// const gcj02Coords = [116.404, 39.915]; // GCJ-02 coordinates
// const wgs84Coords = gcj02ToWgs84(gcj02Coords[0], gcj02Coords[1]);
// console.log(wgs84Coords); // Output: [longitude, latitude] in WGS-84

export {
    gcj02ToWgs84
}
