
import { Options, Vue } from 'vue-class-component';
import {getPlayUrlApi, ptzControlApi, stopPlayApi, getCameraList, addCamera, delCamera} from "@/api/camera";
import {useCache} from "@/hooks/web/useCache";

@Options({
  props: {
    cameraDevice: {
      type: Object,
      default: {}
    },
    saveCameraDevice: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      token: '',
      layoutCnt: 1,
      playUrls: [],
      webPlayer: [],
      cameraDevices: [],
      activePlayer: 0,
      cameraList: []
    }
  },
  mounted() {
    const { wsCache } = useCache()

    this.token = wsCache.get('token')

    this.loadCameraList()
  },
  unmounted() {
    for (let i = 0; i < this.cameraDevices.length; i++) {
      if (this.cameraDevices[i] !== undefined && this.cameraDevices[i] !== null) {
        this.closeMedia(this.cameraDevices[i].imei)
      }
    }
  },
  watch: {
    cameraDevice(val) {
      this.playVideo(val)
    },

    saveCameraDevice(val) {
      this.addCameraItem(val)
    }
  },
  computed: {
    layoutStyl() {
      let rows = ''
      let columns = ''

      if (this.layoutCnt === 1) {
        rows = 'repeat(1, 1fr)'
        columns = 'repeat(1, 1fr)'
      } else if (this.layoutCnt === 4) {
        rows = 'repeat(2, 1fr)'
        columns = 'repeat(2, 1fr)'
      } else if (this.layoutCnt === 9) {
        rows = 'repeat(3, 1fr)'
        columns = 'repeat(3, 1fr)'
      } else if (this.layoutCnt === 16) {
        rows = 'repeat(4, 1fr)'
        columns = 'repeat(4, 1fr)'
      }

      return {
        gridTemplateRows: rows,
        gridTemplateColumns: columns
      }
    }
  },
  methods: {
    switchLayoutStyle(cnt: number) {
      this.layoutCnt = cnt

      if (this.activePlayer > this.layoutCnt - 1) {
        this.activePlayer = this.layoutCnt - 1
      }
    },
    playVideo(device: any) {
      getPlayUrlApi({
        device_id: device.imei,
        channel_no: device.imei
      }).then((res: any) => {
        console.log('58 :::: ', res)
        if (res.code === 200) {
          if (res.data.rtc !== '' && res.data.rtc !== null) {
            this.playUrls[this.activePlayer] = res.data.rtc
            this.cameraDevices[this.activePlayer] = device

            // eslint-disable-next-line no-undef
            this.webPlayer[this.activePlayer] = new ZLMRTCClient.Endpoint({
              element: document.getElementById("video-" + this.activePlayer),// video 标签
              debug: true,// 是否打印日志
              zlmsdpUrl: res.data.rtc,//流地址
              simulecast: false,
              useCamera: false,
              audioEnable: true,
              videoEnable: true,
              recvOnly: true,
              usedatachannel: false,
            })

            // eslint-disable-next-line no-undef
            this.webPlayer[this.activePlayer].on(ZLMRTCClient.Events.WEBRTC_ICE_CANDIDATE_ERROR,(e: any)=>{// ICE 协商出错
              console.error('ICE 协商出错')
              this.eventcallbacK("ICE ERROR", "ICE 协商出错")
            });

            // eslint-disable-next-line no-undef
            this.webPlayer[this.activePlayer].on(ZLMRTCClient.Events.WEBRTC_ON_REMOTE_STREAMS,(e: any)=>{//获取到了远端流，可以播放
              console.log('播放成功',e.streams)
              this.eventcallbacK("playing", "播放成功")
            });

            // eslint-disable-next-line no-undef
            this.webPlayer[this.activePlayer].on(ZLMRTCClient.Events.WEBRTC_OFFER_ANWSER_EXCHANGE_FAILED,(e: any)=>{// offer anwser 交换失败
              console.error('offer anwser 交换失败',e)
              this.eventcallbacK("OFFER ANSWER ERROR ", "offer anwser 交换失败")
              if (e.code ==-400 && e.msg=="流不存在"){
                console.log("流不存在")
                this.timer = setTimeout(()=>{
                  this.webrtcPlayer.close();
                  this.play(res.data.rtc)
                }, 100)

              }
            });

            // eslint-disable-next-line no-undef
            this.webPlayer[this.activePlayer].on(ZLMRTCClient.Events.WEBRTC_ON_LOCAL_STREAM,(s: any)=>{// 获取到了本地流
              // document.getElementById('selfVideo').srcObject=s;
              this.eventcallbacK("LOCAL STREAM", "获取到了本地流")
            });

            if (this.activePlayer < this.layoutCnt - 1) {
              this.activePlayer ++;
            }
          }
        }
      });
    },

    ctrlCamera(cmd: string) {
      if (this.activePlayer >= 0 && this.activePlayer <= this.layoutCnt - 1) {
        const device = this.cameraDevices[this.activePlayer]

        ptzControlApi({
          device_id: device.imei,
          channel_no: device.imei,
          cmd: cmd
        })
      }
    },

    stopCtrlCamera(cmd: string) {
      if (this.activePlayer >= 0 && this.activePlayer <= this.layoutCnt - 1) {
        const device = this.cameraDevices[this.activePlayer]

        ptzControlApi({
          device_id: device.imei,
          channel_no: device.imei,
          cmd: 'stop'
        })
      }
    },

    play() {
      const v: any = document.getElementById('video-' + this.activePlayer)

      v.play()
    },

    pause() {
      const v: any = document.getElementById('video-' + this.activePlayer)

      v.pause()
    },

    closeVideo(i: number) {
      if (this.webPlayer[i] !== null) {
        this.webPlayer[i].close()

        this.webPlayer[i] = null
      }

      if (this.cameraDevices[i] !== undefined && this.cameraDevices[i] !== null) {
        this.closeMedia(this.cameraDevices[i].imei)
      }

      this.playUrls[i] = ''
    },

    cutScreen() {
      console.log('developing ...')

      if (this.activePlayer >= 0 && this.activePlayer <= this.layoutCnt - 1) {
        const video = document.getElementById('video-' + this.activePlayer) as HTMLVideoElement;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set canvas dimensions to match the video
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Draw the current video frame onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert canvas content to a data URL
        const dataUrl = canvas.toDataURL('image/png');

        // Create a link element to download the image
        const downloadLink = document.createElement('a');
        downloadLink.href = dataUrl;
        downloadLink.download = 'screenshot.png';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    },

    download() {
      console.log('developing ...')
    },

    closeMedia(imei: string) {
      stopPlayApi({
        device_id: imei,
        channel_no: imei
      })
    },

    loadCameraList() {
      getCameraList({
        token: this.token
      }).then((res: any) => {
        this.cameraList = res.cameras
      })
    },

    addCameraItem(item: any) {
      addCamera({token: this.token, imei: item.imei}).then((res: any) => {
        this.loadCameraList()
      })
    },

    delCameraItem(imei: any) {
      delCamera({token: this.token, imei: imei}).then((res: any) => {
        this.loadCameraList()
      })
    }
  }
})

export default class CameraMain extends Vue {

}
