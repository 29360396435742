import { useAxios } from '@/hooks/web/useAxios'

const { request } = useAxios()

export const getPlayUrlApi = (data: any) => {
    return request({ url: '/java_api/pc2/camera/get_media_url', method: 'post', data })
}

export const ptzControlApi = (data: any) => {
    return request({ url: '/java_api/pc2/camera/ptz_control', method: 'post', data })
}

export const stopPlayApi = (data: any) => {
    return request({ url: '/java_api/pc2/camera/stop_play', method: 'post', data })
}

export const getCameraList = (data: any = {}) => {
    return request({url: '/php_api/camera/get_camera_list', method: 'post', data})
}

export const addCamera = (data: any) => {
    return request({url: '/php_api/camera/add_camera', method: 'post', data})
}

export const delCamera = (data: any) => {
    return request({url: '/php_api/camera/del_camera', method: 'post', data})
}
