
import { Options, Vue } from 'vue-class-component';
import {useCache} from "@/hooks/web/useCache";
import { getChargeDevicesApi } from "@/api/chargeStation";

@Options({
  props: {
    selectedStation: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      token: '',
      chargeDevices: [],
      timerDown: null,
      refreshTimerDown: null
    }
  },
  mounted() {
    const self = this
    const { wsCache } = useCache()

    this.token = wsCache.get('token')

    this.timerDown = setInterval(function () {
      if (self.chargeDevices.length > 0) {
        const devices = self.chargeDevices

        for (let i = 0; i < self.chargeDevices.length; i++) {
          const item = devices[i]

          if (item.charge_status == 2) {
            item.seconds ++
            item.charge_time = self.calculateTime(item.seconds)

            devices[i] = item
          }
        }

        self.chargeDevices = JSON.parse(JSON.stringify(devices))
        self.$forceUpdate()
      }
    }, 1000)

    this.refreshTimerDown = setInterval(function () {
      if (self.selectedStation !== null) {
        self.getDevices();
      }
    }, 60000)

    this.getDevices()
  },
  unmounted() {
    if (this.timerDown !== null) {
      clearInterval(this.timerDown)
    }
  },
  watch: {
    selectedStation(val) {
      console.log(val)
      this.getDevices()
    }
  },
  computed: {
  },
  methods: {
    getDevices() {
      if (this.selectedStation === null) {
        return
      }

      getChargeDevicesApi({token: this.token, host_no: this.selectedStation.host_no}).then((res: any) => {
        this.chargeDevices = res.devices
      })
    },
    calculateTime: function (seconds: number) {
      const hour = Math.floor(seconds / 3600);
      const minute = Math.floor((seconds - hour * 3600) / 60);
      const second = seconds % 60;

      const h = hour < 10 ? '0' + hour : hour;
      const m = minute < 10 ? '0' + minute : minute;
      const s = second < 10 ? '0' + second : second;

      return h + ':' + m + ':' + s;
    },
    closeChargeDevice() {
      this.$emit('switchTab', 'map')
    }
  }
})

export default class ChargeStationDeviceList extends Vue {

}
