import { useAxios } from '@/hooks/web/useAxios'

const { request } = useAxios()

export const alarmStatusApi = (data: any) => {
  return request({ url: '/php_api/stats/alarm_status', method: 'post', data })
}

export const alarmHandleRecordApi = (data: any) => {
  return request({ url: '/php_api/stats/alarm_handle_record', method: 'post', data })
}

export const monthAlarmAnaliseApi = (data: any) => {
  return request({ url: '/php_api/stats/alarm_count', method: 'post', data })
}
